var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"88ae248544a7ae0a77ba7430856792ada2a2d820"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { APP_VERSION, SENTRY_DNS } from './src/common/const/app.const';

Sentry.init({
  dsn: SENTRY_DNS,
  environment: `${process.env.NODE_ENV}.client`,

  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    }),
    new Sentry.Replay()
  ],
  release: `web-app-client@${APP_VERSION}`,

  replaysOnErrorSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0
});
